//////////////////////////////////
// THEME / VARIABLES / BLOCKQUOTES
//////////////////////////////////


@import '_variables.modular-scale';
@import '_variables.sizing';


// Font size
$font-size-blockquote-open-quote: #{ms(16)}em;  // Open quote font-size: 9.97em
$font-size-blockquote-p: #{ms(2)}em;            // Blockquote paragraph font-size: 1.33em


// Margin
$margin-bottom-blockquote: $length-em-7;        // Blockquote margin-bottom: 2.37em
$margin-left-blockquote: 0;                     // Blockquote margin-left: 0
$margin-right-blockquote: 0;                    // Blockquote margin-right: 0
$margin-top-blockquote: $length-em-4;           // Blockquote margin-top: 1em

$margin-top-attribution: $length-em-4;          // Attribution margin-top: 1em


// Padding
$padding-top-blockquote-p: #{ms(9)}em;          // Blockquote paragraph padding-top: 3.56em
