////////////////////////////////
// THEME / VARIABLES / SUP & SUB
////////////////////////////////


@import '_variables.modular-scale';


// Font size
$font-size-sub-sup: #{ms(-3)}em;    // Subscript & superscript font-size: .63em;


// Position
$position-sub: -#{ms(-4)}ex;        // Subscript bottom position based on x-height: -.56ex
$position-sup: #{ms(2)}ex;          // Superscript bottom position based on x-height: 1.33ex


// Vertical alignment
$vertical-align-sub-sup: baseline;  // Subscript & superscript vertical-align
