//////////////////////////////////
// THEME / COMPONENTS / SLIDESHOWS
//////////////////////////////////


.theme .dcf-slide-caption[aria-hidden="true"] {
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition-duration-fade-out $transition-timing-fn-fade-out;
}


.theme .dcf-slide-caption[aria-hidden="false"] {
  opacity: 1;
  pointer-events: auto;
  transition: opacity $transition-duration-fade-in $transition-timing-fn-fade-in;
}


.theme .dcf-btn-slide-caption {
  background-color: $bg-color-btn-slide-caption-light-mode;
  border-color: $border-color-btn-slide-caption-light-mode;
  bottom: 0;
  color: $color-btn-slide-caption-light-mode;
  left: 0;
  position: absolute;
  z-index: 1;
}


.theme .dcf-slide-caption {
  background-color: $bg-color-slide-caption-light-mode;
  color: $color-slide-caption-light-mode;
  height: 100%;
  left: 0;
  padding: $padding-top-slide-caption $padding-right-slide-caption $padding-bottom-slide-caption $padding-left-slide-caption;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}


.dcf-btn-slide-caption[aria-expanded="false"] .theme-icon-slide-caption-open {
  opacity: 1;
  transition: opacity 250ms ease-out 125ms;
}


.dcf-btn-slide-caption[aria-expanded="true"] .theme-icon-slide-caption-open {
  opacity: 0;
  transition: opacity 250ms ease-out;
}
