///////////////////////////////
// THEME / MIXINS / BACKGROUNDS
///////////////////////////////


// Brand: alpha
@mixin bg-brand-alpha($imp:null) { background-color: $color-brand-alpha $imp; }


// Brand: beta
// @mixin bg-brand-beta($imp:null) { background-color: $color-brand-beta $imp; }


// Gray
@mixin bg-gray($imp:null) { background-color: $gray $imp; }
@mixin bg-gray-lt($imp:null) { background-color: $gray-lt $imp; }
@mixin bg-gray-dk($imp:null) { background-color: $gray-dk $imp; }


// Black
@mixin bg-black($imp:null) { background-color: $black $imp; }
@mixin bg-black-lt($imp:null) { background-color: $black-lt $imp; }
