//////////////////////////////////////////////////
// THEME / VARIABLES / FORMS - CHECKBOXES & RADIOS
//////////////////////////////////////////////////


@import '_variables.body';
@import '_variables.modular-scale';
@import '_variables.sizing';


// Background color
$bg-color-checkbox-radio-light-mode: $white;                      // Background-color for checkboxes and radios in light mode
$bg-color-checkbox-radio-dark-mode: $black;                       // Background-color for checkboxes and radios in dark mode


// Border color
$border-color-input-checked-light-mode: $color-body-light-mode;   // Border-color of input when checked in light mode
$border-color-input-checked-dark-mode: $color-body-dark-mode;     // Border-color of input when checked in dark mode


// Border width
$border-width-checkmark: #{ms(-12)}em;                            // Border-width of border used to create checkmark


// Color
$color-checkmark-light-mode: $color-body-light-mode;              // Checkmark color in light mode
$color-checkmark-dark-mode: $color-body-dark-mode;	              // Checkmark color in dark mode
$color-radio-light-mode: $color-body-light-mode;                  // Radio color in light mode
$color-radio-dark-mode: $color-body-dark-mode;	                  // Radio color in dark mode


// Height & width
$height-width-checkbox-radio: $length-em-6;                       // Height & width for checkboxes and radios


// Margin
$margin-bottom-checkbox-radio: $length-em-1;                      // Margin-bottom for checkboxes and radios


// Padding
$padding-left-checkbox-radio: $length-em-7;                       // Padding-left for checkboxes and radios


// Transform
$scale-radio-start: #{ms(-8)};                                    // Checked radio scale value start: .32em
$scale-radio-end: #{ms(-4)};                                      // Checked radio scale value end: .56em


// Transition
$transition-check-duration: .05s;                                 // Duration of scale transition when checkbox is checked
$transition-check-timing: ease-out;                               // Timing function of scale transition when checkbox is checked
$transition-radio-duration: .05s;                                 // Duration of scale transition when radio is checked
$transition-radio-timing: ease-out;                               // Timing function of scale transition when radio is checked
