////////////////////////////
// THEME / VARIABLES / LISTS
////////////////////////////


@import '_variables.sizing';


// Font weight
$font-weight-dt-bold: true;                 // Bold <dt>? True/false


// Margin
$margin-bottom-dl-ol-ul: $length-em-4;      // Margin-bottom for description, ordered and unordered lists: 1em
$margin-top-dl-ol-ul: 0;                    // Margin-top for description, ordered and unordered lists: 0
$margin-top-nested-dl-ol-ul: $length-em-3;  // Margin-top for nested description, ordered and unordered lists: .75em
$margin-top-li-dt-dd: 0;                    // Margin-top for list items <li>, terms <dt> and descriptions <dd>: 0
$margin-bottom-li: $length-em-2;            // Margin-bottom for <li>: .56em
$margin-left-dd: 0;                         // Margin-left for <dd>: 0
$margin-bottom-dt-dd: $length-em-1;         // Margin-bottom for <dt> and <dd>: .42em
$margin-top-dd-plus-dt: $length-em-4;       // Margin-top for <dd> + <dt>: 1em
$margin-right-li-inline: $length-em-2;      // Margin-right for inline list items: .56em


// Padding
$padding-left-ol-ul: $length-em-6;          // Padding-left for ordered and unordered lists: 1.78em
