////////////////////////////
// THEME / VARIABLES / CARDS
////////////////////////////


@import '_variables.color-palette';
@import '_variables.modular-scale';
@import '_variables.sizing';


// Background color
$bg-color-card-light-mode: $white;        // Card background-color in light mode
$bg-color-card-dark-mode: $white;         // Card background-color in dark mode


// Font size
$font-size-card: #{ms(-1)}em;             // Card font-size: .84em


// Padding
$padding-bottom-card-block: $length-em-7; // Padding-bottom of card block: 2.37em
$padding-left-card-block: $length-em-6;   // Padding-left of card block: 1.77em
$padding-right-card-block: $length-em-6;  // Padding-right of card block: 1.77em
$padding-top-card-block: $length-em-7;    // Padding-bottom of card block: 2.37em
