/////////////////////////////
// THEME / VARIABLES / SIZING
/////////////////////////////


@import '_variables.modular-scale';


// Font size
$font-size-min: 1.125;   // 1.125rem
$font-size-max: 1.125;   // 1.125rem


// Linear inetrpolation
$min-width-lerp: ms(24);    //  31.57em ~  505.12px
$max-width-lerp: ms(36);    // 177.38em ~ 2838.08px


// Em-based spacing
$length-em-1: #{ms(-6)}em;  //  .42em
$length-em-2: #{ms(-4)}em;  //  .56em
$length-em-3: #{ms(-2)}em;  //  .75em
$length-em-4: #{ms(0)}em;   // 1em
$length-em-5: #{ms(2)}em;   // 1.33em
$length-em-6: #{ms(4)}em;   // 1.78em
$length-em-7: #{ms(6)}em;   // 2.37em
$length-em-8: #{ms(8)}em;   // 3.16em
$length-em-9: #{ms(10)}em;  // 4.21em
$length-em-10: #{ms(12)}em; // 5.62em
$length-em-11: #{ms(14)}em; // 7.49em
$length-em-12: #{ms(16)}em; // 10em

// Viewport-width-based spacing
$length-vw-1: #{ms(8)}vw;   // 3.16vw
$length-vw-2: #{ms(12)}vw;  // 5.62vw
