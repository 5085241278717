/////////////////////////////
// THEME / VARIABLES / BADGES
/////////////////////////////


@import '_variables.color-palette';
@import '_variables.modular-scale';
@import '_variables.sizing';


// Background color
$bg-color-badge-light-mode: $gray-dk;     // Background-color of badge in light mode
$bg-color-badge-dark-mode: $gray-dk;      // Background-color of badge in dark mode


// Color
$color-badge-light-mode: $white;          // Color of badge text in light mode
$color-badge-dark-mode: $white;           // Color of badge text in dark mode


// Font size
$font-size-badge: #{ms(-4)}rem;           // Font-size of badge: .56rem


// Padding
$padding-bottom-badge: $length-em-1;      // Padding-bottom of <mark> (highlighted) text: .42em
$padding-left-badge: $length-em-2;        // Padding-left of <mark> (highlighted) text: .56em
$padding-right-badge: $length-em-2;       // Padding-right of <mark> (highlighted) text: .56em
$padding-top-badge: $length-em-1;         // Padding-top of <mark> (highlighted) text: .42em

$padding-left-badge-pill: $length-em-3;   // Increase horizontal padding to account for increased border-radius: .75em
$padding-right-badge-pill: $length-em-3;  // Increase horizontal padding to account for increased border-radius: .75em


// Vertical alignment
$vertical-align-badge: text-top;          // Vertical-align of badge
