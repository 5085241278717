//////////////////////////////////
// THEME / VARIABLES / TRANSITIONS
//////////////////////////////////


// Duration
$transition-duration-fade-in: 200ms;
$transition-duration-fade-out: 400ms;
$transition-duration-hover-on: 200ms;
$transition-duration-hover-off: 400ms;


// Timing Function (Easing)
$transition-timing-fn-fade-in: cubic-bezier(.25, .46, .45, .94);
$transition-timing-fn-fade-out: cubic-bezier(.25, .46, .45, .94);
$transition-timing-fn-hover-on: ease-out;
$transition-timing-fn-hover-off: ease-out;
