//////////////////////////////
// THEME / COMPONENTS / HEADER
//////////////////////////////


// @include mq(md, min, width) {
//
//   .dcf-header-global-child {
//     margin-left: $length-vw-1;
//   }
//
// }


// Adjust existing UNMC markup
.unmc .preheader__audience {
  margin-bottom: 0;
}

.unmc .header__sub-title a {
  border-bottom: 0;
}
