/////////////////////////////
// THEME / UTILITIES / COLORS
/////////////////////////////


// Brand: Alpha
.unmc-brand-alpha { @include brand-alpha(!important); }


// Brand: Beta
// .unmc-brand-beta { @include brand-beta(!important); }


// Gray
.unmc-gray { @include gray(!important); }
.unmc-gray-lt { @include gray-lt(!important); }
.unmc-gray-dk { @include gray-dk(!important); }


// Black
.unmc-gray-black { @include black(!important); }
.unmc-gray-black-lt { @include black-lt(!important); }
