////////////////////////////////
// THEME / VARIABLES / ANIMATION
////////////////////////////////


// Delay
$animation-delay-fade-in: 0;
$animation-delay-move-left: 500ms;
$animation-delay-move-right: 500ms;
$animation-delay-move-up: 500ms;


// Duration
$animation-duration-fade-in: 500ms;
$animation-duration-move-left: 250ms;
$animation-duration-move-right: 250ms;
$animation-duration-move-up: 250ms;


// Timing Function (Easing)
$animation-timing-fn-fade-in: cubic-bezier(.25, .46, .45, .94);
$animation-timing-fn-move-left: cubic-bezier(.25, .46, .45, .94);
$animation-timing-fn-move-right: cubic-bezier(.25, .46, .45, .94);
$animation-timing-fn-move-up: cubic-bezier(.25, .46, .45, .94);


// Transforms
$animation-transform-move-left: #{ms(8)}em;
$animation-transform-move-right: -#{ms(8)}em;
$animation-transform-move-up: #{ms(8)}em;
