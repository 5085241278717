//////////////////////////
// THEME / MIXINS / COLORS
//////////////////////////


// Brand: alpha
@mixin brand-alpha($imp:null) { color: $color-brand-alpha $imp; }


// Brand: beta
// @mixin brand-beta($imp:null) { color: $color-brand-beta $imp; }


// Gray
@mixin gray($imp:null) { color: $gray $imp; }
@mixin gray-lt($imp:null) { color: $gray-lt $imp; }
@mixin gray-dk($imp:null) { color: $gray-dk $imp; }


// Black
@mixin black($imp:null) { color: $black $imp; }
@mixin black-lt($imp:null) { color: $black-lt $imp; }
