////////////////////////////////////////
// THEME / VARIABLES / DETAILS & SUMMARY
////////////////////////////////////////


@import '_variables.modular-scale';
@import '_variables.sizing';


// Font size
$font-size-summary: #{ms(1)}em;                     // Summary font-size: 1.13em


// Font weight
$font-weight-summary-bold: true;                    // Summary font-weight bold? True/false


// Margin
$margin-bottom-details-open-summary: #{ms(-4)}rem;  // Margin-bottom of details


// Padding
$padding-bottom-details: $length-em-3;              // Padding-bottom of details
$padding-top-details: $length-em-3;                 // Padding-top of details
