//////////////////////////////////
// THEME / VARIABLES / BACKGROUNDS
//////////////////////////////////


@import '_variables.color-palette';


// Background color
$bg-color-overlay-dark-light-mode: fade-out($black-lt,.06);   // Dark overlay background-color for modals in light mode
$bg-color-overlay-dark-dark-mode: fade-out($black-lt,.06);    // Dark overlay background-color for modals in dark mode

$bg-color-overlay-light-light-mode: fade-out($white,.06);     // Light overlay background-color for modals in light mode
$bg-color-overlay-light-dark-mode: fade-out($white,.06);      // Light overlay background-color for modals in dark mode
