/////////////////////////////////
// THEME / VARIABLES / SLIDESHOWS
/////////////////////////////////


@import '_variables.backgrounds';
@import '_variables.body';
@import '_variables.borders';
@import '_variables.color-palette';
@import '_variables.sizing';


// Background color
$bg-color-btn-slide-caption-light-mode: $bg-color-overlay-dark-light-mode;  // Slide caption toggle button background-color in light mode
$bg-color-btn-slide-caption-dark-mode: $bg-color-overlay-dark-light-mode;   // Slide caption toggle button background-color in dark mode

$bg-color-slide-caption-light-mode: $bg-color-overlay-dark-light-mode;      // Slide caption background-color in light mode
$bg-color-slide-caption-dark-mode: $bg-color-overlay-dark-light-mode;       // Slide caption background-color in dark mode


// Border color
$border-color-btn-slide-caption-light-mode: transparent;            // Caption toggle button border-color in light mode
$border-color-btn-slide-caption-dark-mode: transparent;             // Caption toggle button border-color in dark mode


// Color
$color-btn-slide-caption-light-mode: $color-inverse-light-mode;     // Slide caption toggle button color in light mode
$color-btn-slide-caption-dark-mode: $color-inverse-dark-mode;       // Slide caption toggle button color in dark mode

$color-slide-caption-light-mode: $color-inverse-light-mode;         // Slide caption color in light mode
$color-slide-caption-dark-mode: $color-inverse-dark-mode;           // Slide caption color in dark mode


// Padding
$padding-bottom-slide-caption: $length-em-4;                        // Padding-bottom of slide caption: 1em
$padding-left-slide-caption: $length-em-4;                          // Padding-left of slide caption: 1em
$padding-right-slide-caption: $length-em-4;                         // Padding-right of slide caption: 1em
$padding-top-slide-caption: $length-em-4;                           // Padding-top of slide caption: 1em
