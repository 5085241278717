/////////////////////////////
// THEME / VARIABLES / MODALS
/////////////////////////////


@import '_variables.body';


// Background color
$bg-color-modal-wrapper-light-mode: $bg-color-body-light-mode;  // Modal wrapper background-color in light mode
$bg-color-modal-wrapper-dark-mode: $bg-color-body-dark-mode;    // Modal wrapper background-color in dark mode

$bg-color-modal-header-light-mode: $bg-color-body-light-mode;   // Modal header background-color in light mode
$bg-color-modal-header-dark-mode: $bg-color-body-dark-mode;     // Modal header background-color in dark mode


// Height & width
$height-max-modal-wrapper: 75vh;                                // Max-height modal wrapper
$width-modal-wrapper: calc(100% - (2 * #{ms(12)}vw));           // Match width of .dcf-wrapper
