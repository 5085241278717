//////////////////////////////
// THEME / VARIABLES / BORDERS
//////////////////////////////


@import '_variables.color-palette';
@import '_variables.sizing';


// Border color
$border-color-light-mode: $black-lt;  // Border-color in light mode
$border-color-dark-mode: $black-lt;   // Border-color in dark mode


// Border radius
$circle: 50%;
$pill: 1.875rem; // 1.875rem
$roundrect: 2px;
$sharp: 0;
