/////////////////////////////
// THEME / VARIABLES / COLORS
/////////////////////////////


// BRAND COLORS

// Define brand colors
$color-brand-alpha: rgb(180, 24, 45);
// $color-brand-beta: #c00;

// Optionally assign names to brand colors
// $namedcolor1: $color-brand-alpha;
// $namedcolor2: $color-brand-beta;


// UI COLORS

// Neutrals
$white: #fff;
$black: #000;

$black-lt: rgb(42, 42, 42);   // #2a2a2a

$gray: rgb(111, 111, 111);    // #6f6f6f
$gray-lt: rgb(238, 239, 239); // #efefef
$gray-dk: rgb(84, 88, 90);    // #54585a

// $gray-light: rgb(238, 239, 239);      // #eeefef
// $gray-lighter: lighten($gray, 36%);   // #dcdcdc
// $gray-lightest: lighten($gray, 47%);  // #f8f8f8
//
// $gray-dark: rgb(84, 88, 90);          // #2b2b2b
// $gray-darker: darken($gray, 24%);     // #434343
// $gray-darkest: darken($gray, 32%);    // #2e2e2e

// Miscellaneous
$color-highlight: #ff0;
$color-required: $color-brand-alpha;
$color-kbd: #0f0;

// Contextual colors
$color-info: #40647e;
$color-success: #47682C;
$color-warning: #c74a10;
$color-danger: #781d1d;


// SOCIAL MEDIA COLORS
$color-facebook: #3b5998;             // Facebook
$color-instagram: #c13584;            // Instagram
$color-linkedin: #0077b5;             // LinkedIn
$color-pinterest: #bd081c;            // Pinterest
$color-snapchat: #fffc00;             // SnapChat
$color-twitter: #1da1f2;              // Twitter
$color-youtube: #cd201f;              // YouTube
