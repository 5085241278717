///////////////////////////////
// THEME / COMPONENTS / BUTTONS
///////////////////////////////



// .theme .dcf-btn {
//   transition: all $transition-duration-hover-off $transition-timing-fn-hover-off;
// }


// .theme .dcf-btn:hover {
//   transition: all $transition-duration-hover-on $transition-timing-fn-hover-on;
// }


.unmc .dcf-btn {
  border-radius: 1.875rem;
  font-size: $font-size-button !important;  // Fix font-size in DCF elements.buttons.scss
}


.unmc .dcf-btn:not(:disabled):hover {
  border-bottom-width: 1px; // Override link :hover border underlines
}


// Primary Button
.dcf-btn-primary:visited {
  color: var(--btn-primary);
}

.unmc .dcf-btn-primary:not(:disabled):hover {
  background-image: linear-gradient(rgba(var(--black),.15),rgba(var(--black),.15));
}


// .theme .dcf-btn-primary:visited {
//   background-color: $color-visited-light-mode;
//   border-color: $color-visited-light-mode;
// }


// .theme .dcf-btn-primary:active,
// .theme .dcf-btn-primary.dcf-active {
//   background-color: $color-active-light-mode;
//   border-color: $color-active-light-mode;
// }


// Secondary Button
.unmc .dcf-btn-secondary:visited {
  border-color: var(--b-btn-secondary);
  color: var(--btn-secondary);
}


// .theme .dcf-btn-secondary:active,
// .theme .dcf-btn-secondary.dcf-active {
//   color: $color-active-light-mode;
// }


.unmc .dcf-btn-secondary:not(:disabled):hover,
.unmc .dcf-btn-tertiary:not(:disabled):hover {
  background-color: var(--bg-btn-secondary-tertiary-hover);
}


// Tertiary Button
// .theme .dcf-btn-tertiary:visited {
//   color: $color-visited-light-mode;
// }


// .theme .dcf-btn-tertiary:active,
// .theme .dcf-btn-tertiary.dcf-active {
//   color: $color-active-light-mode;
// }


// .theme .dcf-btn-tertiary:not(:disabled):hover {
//   background-color: lighten($color-button-tertiary-light-mode,56%);
//   color: $color-hover-light-mode;
// }


// Inverse Buttons
// .theme .dcf-btn-inverse-primary:not(:disabled):hover {
//   background-color: fade-out($bg-color-button-inverse-primary-light-mode, .16);
// }


.unmc .dcf-btn-inverse-secondary:not(:disabled):hover,
.unmc .dcf-btn-inverse-tertiary:not(:disabled):hover {
  background-color: fade-out($color-button-inverse-secondary-light-mode, .84);
}
