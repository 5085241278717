///////////////////////////////////
// THEME / COMPONENTS / NAV: TOGGLE
///////////////////////////////////


.theme .dcf-nav-toggle-btn {
  flex-basis: 25%;
}


.theme .dcf-nav-toggle-btn-menu {
  left: 0;
}


@include mq(md, max, width) {

  .theme .dcf-nav-toggle-group {
    display: flex;
    z-index: $z-nav-toggle-group;
  }


  .theme .dcf-nav-toggle-group::after {
    bottom: 0;
    box-shadow: 0 0 4px 0 fade-out($color-body-light-mode, .75);
    content: '';
    height: #{ms(10)}em;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: $z-nav-toggle-group-after;
  }

}


@include mq(md, min, width) {

  .theme .dcf-nav-toggle-group {
    display: none;
  }

}
