///////////////////////////////
// THEME / VARIABLES / HEADINGS
///////////////////////////////


@import '_variables.color-palette';
@import '_variables.modular-scale';


// Color -- strive for WCAG AAA (minimum contrast ratio of 7.0) for heading text
$color-heading-light-mode: $black-lt;         // Heading color in light mode
$color-heading-dark-mode: $black-lt;          // Heading color in dark mode


// Font size
$font-size-subhead: #{ms(-2)}rem;             // Subhead font-size: .75rem


// Line height
$line-height-heading: #{ms(1)};               // Heading line-height


// Margin
$margin-bottom-heading: #{ms(0)}rem;          // Heading margin-bottom: 1rem
$margin-top-heading: 0;                       // Heading margin-top: 0
$margin-top-heading-after-els: $length-em-5;  // Heading margin-top after elements: 1.33em

$margin-bottom-subhead: $length-em-4;         // Subhead margin-bottom: 1em


// Text transform
$text-transform-subhead: true;                // Uppercase subhead? True/false
