//////////////////////////////
// THEME / COMPONENTS / GLOBAL
//////////////////////////////


.unmc .dcf-datepicker-dialog[aria-hidden="true"] {
  transition: opacity $transition-duration-fade-out $transition-timing-fn-fade-out;
}

.unmc .dcf-datepicker-dialog[aria-hidden="false"] {
  transition: opacity $transition-duration-fade-in $transition-timing-fn-fade-in;
}

.unmc .dcf-datepicker-dialog-header {
  border-bottom: 1px dotted $black-lt;
}


.unmc .dcf-datepicker-dialog-calendar td[aria-selected] {
  background-color: $white;
  color: $black-lt;
}


.unmc .dcf-datepicker-dialog-calendar td:not(.disabled):not([tabindex="0"]):not([aria-selected]) {
  transition: background-color $transition-duration-hover-off $transition-timing-fn-hover-off, border-color $transition-duration-hover-off $transition-timing-fn-hover-off, color $transition-duration-hover-off $transition-timing-fn-hover-off;
}


.unmc .dcf-datepicker-dialog-calendar td:not(.disabled):not([tabindex="0"]):not([aria-selected]):hover {
  transition: background-color $transition-duration-hover-on $transition-timing-fn-hover-on, border-color $transition-duration-hover-on $transition-timing-fn-hover-on, color $transition-duration-hover-on $transition-timing-fn-hover-on;
}


.unmc .dcf-datepicker-dialog-calendar td[tabindex="0"]:not(.disabled),
.unmc .dcf-datepicker-dialog-calendar td:not(.disabled):focus {
  outline: 3px solid transparent; // https://sarahmhigley.com/writing/whcm-quick-tips/
  transition: box-shadow $transition-duration-hover-on $transition-timing-fn-hover-on;
}


.unmc .dcf-datepicker-dialog-calendar td[tabindex="0"]:not(.disabled),
.unmc .dcf-datepicker-dialog-calendar td:not(.disabled):focus,
.unmc .dcf-datepicker-dialog-calendar td:not(.disabled):active {
  box-shadow: 0 0 0 3px $gray-dk, 0 0 0 5px currentColor;
  position: relative; // Pair with z-index to ensure that box-shadow appears above adjacent elements
  z-index: 2; // Pair with 'position: relative' to ensure that box-shadow appears above adjacent elements
}


.unmc .dcf-datepicker-dialog-calendar td[aria-selected][tabindex="0"] {
  box-shadow: 0 0 0 3px $white, 0 0 0 5px $gray-dk; // Match aria-selected selected background-color
}


.unmc .dcf-datepicker-dialog-footer {
  border-top: 1px dotted $black-lt;
}
