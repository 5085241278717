////////////////////////////
// THEME / COMPONENTS / TABS
////////////////////////////


.theme .dcf-tab {
  margin-bottom: -#{$border-width-tab};
}


.theme .dcf-tab[aria-selected] {
  border-bottom-color: $bg-color-tab-selected-light-mode;
  border-left-color: $border-color-tab-light-mode;
  border-right-color: $border-color-tab-light-mode;
  border-top-color: $border-color-tab-light-mode;
  border-style: $border-style-tab;
  border-width: $border-width-tab;
}


.theme .dcf-tabs-panel > *:last-child {
  margin-bottom: 0;
}


// Responsive tabs
@include mq(md, max, width) {

  .theme .dcf-tabs-responsive .dcf-tab {
    border-bottom-width: 0;
    border-left: $border-width-tab $border-style-tab $color-tab-selected-light-mode;
    border-right: $border-width-tab $border-style-tab $border-color-tab-light-mode;
    border-top: $border-width-tab $border-style-tab $border-color-tab-light-mode;
    margin-bottom: 0;
  }


  .theme .dcf-tabs-responsive .dcf-tab[aria-selected] {
    position: relative;
  }


  .theme .dcf-tabs-responsive .dcf-tab[aria-selected]::before {
    border-bottom: .47em solid transparent;
    border-left: .47em solid $gray;
    border-top: .47em solid transparent;
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

}
