//////////////////////////////
// THEME / UTILITIES / BORDERS
//////////////////////////////


// Gray
.unmc-b-gray { @include b-color(!important); }     // All sides
.unmc-bt-gray { @include bt-color(!important); }   // Top
.unmc-br-gray { @include br-color(!important); }   // Right
.unmc-bb-gray { @include bb-color(!important); }   // Bottom
.unmc-bl-gray { @include bl-color(!important); }   // Left


// Brand: Alpha
.unmc-b-alpha { @include b-alpha(!important); }    // All sides
.unmc-bt-alpha { @include bt-alpha(!important); }  // Top
.unmc-br-alpha { @include br-alpha(!important); }  // Right
.unmc-bb-alpha { @include bb-alpha(!important); }  // Bottom
.unmc-bl-alpha { @include bl-alpha(!important); }  // Left


// Brand: Beta
// .unmc-b-beta { @include b-beta(!important); }      // All sides
// .unmc-bt-beta { @include bt-beta(!important); }    // Top
// .unmc-br-beta { @include br-beta(!important); }    // Right
// .unmc-bb-beta { @include bb-beta(!important); }    // Bottom
// .unmc-bl-beta { @include bl-beta(!important); }    // Left
