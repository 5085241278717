///////////////////////////////////
// THEME / COMPONENTS / NAV: GLOBAL
///////////////////////////////////


.theme .dcf-nav-global {
  flex-grow: 5;
}


.theme .dcf-nav-global li:not(:last-child) {
  margin-right: #{ms(4)}vw;
}
