/////////////////////////////////
// THEME / UTILITIES / TYPOGRAPHY
/////////////////////////////////


// Font size
.unmc .dcf-txt-3xs { @include txt-3xs(!important); }
.unmc .dcf-txt-2xs { @include txt-2xs(!important); }
.unmc .dcf-txt-xs { @include txt-xs(!important); }
.unmc .dcf-txt-sm { @include txt-sm(!important); }
.unmc .dcf-txt-md { @include txt-md(!important); }
.unmc .dcf-txt-lg { @include txt-lg(!important); }
.unmc .dcf-txt-h6 { @include txt-h6(!important); }
.unmc .dcf-txt-h5 { @include txt-h5(!important); }
.unmc .dcf-txt-h4 { @include txt-h4(!important); }
.unmc .dcf-txt-h3 { @include txt-h3(!important); }
.unmc .dcf-txt-h2 { @include txt-h2(!important); }
.unmc .dcf-txt-h1 { @include txt-h1(!important); }
.unmc .dcf-txt-xl { @include txt-xl(!important); }
.unmc .dcf-txt-2xl { @include txt-2xl(!important); }
.unmc .dcf-txt-3xl { @include txt-3xl(!important); }
.unmc .dcf-txt-4xl { @include txt-4xl(!important); }
.unmc .dcf-txt-5xl { @include txt-5xl(!important); }
.unmc .dcf-txt-6xl { @include txt-6xl(!important); }


// Letter spacing
// .unmc-ls-0 { @include ls-0(!important); }
// .unmc-ls-1 { @include ls-1(!important); }
// .unmc-ls-2 { @include ls-2(!important); }
