//////////////////////////////
// THEME / VARIABLES / FIGURES
//////////////////////////////


@import '_variables.color-palette';
@import '_variables.modular-scale';


// Color
$color-figcaption-light-mode: $color-light-text-light-mode;   // Color of figcaption in light mode
$color-figcaption-dark-mode: $color-light-text-dark-mode;     // Color of figcaption in dark mode


// Font size
$font-size-figcaption: #{ms(-2)}em;                           // Font-size of figcaption: .75em
