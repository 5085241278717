/////////////////////////////////
// THEME / VARIABLES / PARAGRAPHS
/////////////////////////////////


@import '_variables.modular-scale';


// Font size
$font-size-drop-cap: #{ms(11)}em;       // Drop cap font-size: 4.74em


// Line height
// Adjust line-height for Chrome & Safari after first adjusting margin-top for Firefox
$line-height-drop-cap: #{ms(-3)};       // Drop cap line-height: .63


// Margin
$margin-bottom-paragraph: #{ms(0)}rem;  // Paragraph margin-bottom: 1rem
$margin-top-paragraph: 0;               // Paragraph margin-top: 0
// Adjust margin-top for Firefox, then adjust line-height
$margin-top-drop-cap: #{ms(-1)}rem;     // Drop cap margin-top: .84rem


// Padding
$padding-left-drop-cap: #{ms(-20)}rem;  // Padding-left for drop cap: .06rem
$padding-right-drop-cap: #{ms(-4)}rem;  // Padding-right for drop cap: .56rem
