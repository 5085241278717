//////////////////////////////////
// THEME / VARIABLES / DATEPICKERS
//////////////////////////////////


// Border radius
$border-radius-datepicker-dialog-roundrect: true;                   // Datepicker dialog border-radius roundrect? True/false
$border-radius-datepicker-date-roundrect: true;                     // Datepicker date border-radius roundrect? True/false


// Box shadow
$box-shadow-datepicker-dialog: 1px 1px 5px rgba(0,0,0,.1), 1px 1px 30px rgba(0,0,0,.1);


// Font size
$font-size-datepicker-dialog-month-year: #{ms(-1)}em;               // Datepicker dialog month/year font-size: .84em
$font-size-datepicker-dialog-calendar: #{ms(-2)}em;                 // Datepicker dialog calendar font-size: .75em
$font-size-datepicker-dialog-btn-footer: #{ms(-2)}rem;              // Datepicker dialog footer button font-size: .75rem
$font-size-datepicker-dialog-message: #{ms(-3)}em;                  // Datepicker dialog message font-size: .63em


// Height
$height-datepicker-dialog-calendar-th-td: #{ms(6)}rem;              // Datepicker dialog calendar cell (th/td) height: 2.37rem


// Margin
$margin-top-datepicker-dialog: #{ms(-12)}em;                        // Margin-top datepicker dialog: .18em
$margin-top-datepicker-dialog-footer: #{ms(-10)}em;                 // Margin-top datepicker dialog footer: .24em


// Padding
$padding-bottom-datepicker-dialog: $length-em-3;                    // Padding-bottom datepicker dialog header: .75em
$padding-left-datepicker-dialog: $length-em-4;                      // Padding-left datepicker dialog header: 1em
$padding-right-datepicker-dialog: $length-em-4;                     // Padding-right datepicker dialog header: 1em
$padding-top-datepicker-dialog: $length-em-2;                       // Padding-top datepicker dialog header: .56em

$padding-bottom-datepicker-dialog-header: #{ms(-10)}em;             // Padding-bottom datepicker dialog header: .24em

$padding-top-datepicker-dialog-footer: $length-em-2;                // Padding-top datepicker dialog footer: .56em


// Width
$width-datepicker-dialog: #{ms(21)}rem;                             // Datepicker dialog width: 19.98rem (~320px)
