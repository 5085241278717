//////////////////////////////
// THEME / MIXINS / TYPOGRAPHY
//////////////////////////////


// Font size
@mixin txt-3xs($imp:null) { font-size: #{ms(-4)}em $imp; }
@mixin txt-2xs($imp:null) { font-size: #{ms(-3)}em $imp; }
@mixin txt-xs($imp:null) { font-size: #{ms(-2)}em $imp; }
@mixin txt-sm($imp:null) { font-size: #{ms(-1)}em $imp; }
@mixin txt-md($imp:null) { font-size: #{ms(0)}em $imp; }
@mixin txt-lg($imp:null) { font-size: #{ms(1)}em $imp; }

@mixin txt-h6($imp:null) {
  font-size: #{ms(0)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(1)}em $imp;
  }

}

@mixin txt-h5($imp:null) {
  font-size: #{ms(1)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(2)}em $imp;
  }

}

@mixin txt-h4($imp:null) {
  font-size: #{ms(2)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(3)}em $imp;
  }

}

@mixin txt-h3($imp:null) {
  font-size: #{ms(3)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(4)}em $imp;
  }

}

@mixin txt-h2($imp:null) {
  font-size: #{ms(4)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(5)}em $imp;
  }

}

@mixin txt-h1($imp:null) {
  font-size: #{ms(5)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(6)}em $imp;
  }

}

@mixin txt-xl($imp:null) {
  font-size: #{ms(6)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(7)}em $imp;
  }

}

@mixin txt-2xl($imp:null) {
  font-size: #{ms(7)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(8)}em $imp;
  }

}


@mixin txt-3xl($imp:null) {
  font-size: #{ms(8)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(9)}em $imp;
  }

}

@mixin txt-4xl($imp:null) {
  font-size: #{ms(9)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(10)}em $imp;
  }

}

@mixin txt-5xl($imp:null) {
  font-size: #{ms(10)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(11)}em $imp;
  }

}

@mixin txt-6xl($imp:null) {
  font-size: #{ms(11)}em $imp;

  @include mq(md, min, width) {
    font-size: #{ms(12)}em $imp;
  }

}


// Letter spacing
@mixin ls-0($imp:null) { letter-spacing: 0 $imp; }
@mixin ls-1($imp:null) { letter-spacing: .01em $imp; }
@mixin ls-2($imp:null) { letter-spacing: .1em $imp; }
