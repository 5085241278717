//////////////////////////////
// THEME / VARIABLES / Z-INDEX
//////////////////////////////


$z-0: 0;
$z-1: 1;
$z-2: 2;

$z-nav-toggle-group: 100000;
$z-nav-toggle-group-after: -2;

$z-modal-fullscreen: 1000000;
$z-modal-behind-nav-toggle-group: $z-nav-toggle-group - 1000;
