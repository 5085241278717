//////////////////////////////////
// THEME / COMPONENTS / NAV: LOCAL
//////////////////////////////////


// .theme .dcf-nav-local > ul > li > a {
//   font-weight: bold;
//   @include ls-1;
//   text-transform: uppercase;
// }
//
//
// .theme .dcf-nav-local > ul {
//   display: grid;
//   @include txt-xs;
//   grid-gap: 1em 5vw;
//   grid-template-columns: repeat(auto-fit, minmax(#{ms(18)}em, 1fr));
// }
//
//
// .theme .dcf-nav-local ul ul {
//   @include txt-xs;
//   @include pt-4;
// }
//
//
// @include mq(md, min, width) {
//
//   .theme .dcf-nav-local {
//     grid-area: nav1;
//     position: relative;
//   }
//
//   .theme .dcf-nav-local > ul {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//   }
//
//   .theme .dcf-nav-local > ul > li:not(:last-child) {
//     margin-right: 2vw;
//   }
//
//   .theme .dcf-nav-local ul ul {
//     position: absolute;
//     @include sr-only;
//   }
//
// }


// Adjust existing UNMC markup
.unmc .header__nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.unmc .header__nav a {
  border: 0;
  font-weight: bold;
}


@media (min-width: 64em) {

  .unmc .header__sub-title-wrapper > :first-child {
    font-size: 1.875rem;
  }

  .unmc .header__nav ul > li {
    border: 0;
    margin-right: 2rem;
  }

}
