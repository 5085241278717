////////////////////////////
// THEME / VARIABLES / FORMS
////////////////////////////


@import '_variables.body';
@import '_variables.borders';
@import '_variables.color-palette';
@import '_variables.modular-scale';
@import '_variables.sizing';


// Background color
$bg-color-input-light-mode: $gray-lt;                           // Input field background-color in light mode
$bg-color-input-dark-mode: $gray-lt;                            // Input field background-color in dark mode


// Border color
$border-color-fieldset-light-mode: $gray-lt;                    // Fieldset border-color in light mode
$border-color-fieldset-dark-mode: $gray-lt;                     // Fieldset border-color in dark mode

$border-color-input-light-mode: $gray-lt;                       // Input field border-color in light mode
$border-color-input-dark-mode: $gray-lt;                        // Input field border-color in dark mode

$border-color-input-hover-light-mode: $gray-lt;                 // Input field border-color when hovered in light mode
$border-color-input-hover-dark-mode: $gray-lt;                  // Input field border-color when hovered in dark mode

$border-color-input-focus-light-mode: transparent;              // Input field border-color when focused in light mode
$border-color-input-focus-dark-mode: transparent;               // Input field border-color when focused in dark mode


// Border radius
$border-radius-input-roundrect: false;                          // Input field border-radius roundrect? True/false
$border-radius-fieldset-roundrect: false;                       // Fieldset border-radius roundrect? True/false


// Border style
$border-style-input: solid;                                     // Input field border-style


// Border width
$border-width-input: 1px;                                       // Input field border-width


// Color
$color-form-help-light-mode: $gray-dk;                          // Form help text color in light mode
$color-form-help-dark-mode: $gray-dk;                           // Form help text color in dark mode

$color-required-label-light-mode: $color-required;              // Required label text color in light mode
$color-required-label-dark-mode: $color-required;               // Required label text color in dark mode


// Font size
$font-size-label: .9375rem;                                     // Label font-size: .9375rem
$font-size-legend: #{ms(-1)}em;                                 // Legend font-size: .84em
$font-size-form-help: #{ms(-2)}em;                              // Form help font-size: .75em
$font-size-required: #{ms(-2)}em;                               // Required label font-size: .75em


// Font style
$font-style-required-italic: true;                              // Italic font-style for required label? True/false


// Font weight
$font-weight-legend-bold: true;                                 // Bold legend? True/false


// Line height
$line-height-form-help: 1.3125;                                 // Form help line-height


// Margin
$margin-bottom-fieldset: $length-em-4;                          // Fieldset margin-bottom: 1em
$margin-bottom-form-label: .5rem;                               // Form label margin-bottom: .5rem
$margin-bottom-form-group: 1rem;                                // Form group margin-bottom: 1rem
$margin-left-required: $length-em-1;                            // Margin-left of required label: .42em
$margin-right-form-controls-inline-label: $length-em-4;         // Form controls inline label margin-right: 1em
$margin-top-form-help: $length-em-1;                            // Form help margin-top: .42em


// Opacity
$opacity-disabled: .75;                                         // Opacity for disabled elements


// Padding
$padding-bottom-fieldset: $length-em-4;                         // Padding-bottom of fieldset: 1em
$padding-left-fieldset: $length-vw-1;                           // Padding-left of fieldset: 3.16vw
$padding-right-fieldset: $length-vw-1;                          // Padding-right of fieldset: 3.16vw
$padding-top-fieldset: $length-em-4;                            // Padding-top of fieldset: 1em

$padding-bottom-required: $padding-bottom-badge;                // Padding-bottom of required label: .42em
$padding-left-required: $padding-left-badge-pill;               // Padding-left of required label: .75em
$padding-right-required: $padding-right-badge-pill;             // Padding-right of required label: .75em
$padding-top-required: $padding-top-badge;                      // Padding-top of required label: .42em

$padding-bottom-input: 1rem;                                    // Padding-bottom of input field: 1rem
$padding-left-input: .75rem;                                    // Padding-left of input field: .75rem
$padding-right-input: .75rem;                                   // Padding-right of input field: .75rem
$padding-top-input: 1rem;                                       // Padding-top of input field: 1rem

$padding-legend: $length-em-2;                                  // Padding around legend

$padding-left-required: $length-em-1;                           // Padding-left of required label: .42em
