//////////////////////////////////
// THEME / UTILITIES / BACKGROUNDS
//////////////////////////////////


// Brand: Alpha
.unmc-bg-brand-alpha { @include bg-brand-alpha(!important); }


// Brand: Beta
// .unmc-bg-brand-beta { @include bg-brand-beta(!important); }


// Gray
.unmc-bg-gray { @include bg-gray(!important); }
.unmc-bg-gray-lt { @include bg-gray-lt(!important); }
.unmc-bg-gray-dk { @include bg-gray-dk(!important); }


// Black
.unmc-bg-black { @include bg-black(!important); }
.unmc-bg-black-lt { @include bg-black-lt(!important); }
