//////////////////////////////
// THEME / COMPONENTS / NOTICE
//////////////////////////////


// Add drop shadow to notice
.unmc .dcf-notice {
  filter:
          drop-shadow(1px 2px 2px rgba(0,0,0,.15))
          drop-shadow(2px 4px 4px rgba(0,0,0,.15))
          drop-shadow(3px 6px 6px rgba(0,0,0,.15));
}


// Add a little vertical space between notice heading and message
.unmc .dcf-notice-message {
  margin-top: #{ms(-12)}em;
}


// Ensure heading and close link (all states) display as cream
// .unmc .dcf-notice h1, // An <h1> shouldn't be used at this level.
.unmc .dcf-notice h2,
.unmc .dcf-notice h3,
.unmc .dcf-notice h4,
.unmc .dcf-notice h5,
.unmc .dcf-notice h6,
.unmc .dcf-notice-message,
.unmc .dcf-notice-message a:link,
.unmc .dcf-notice-message a:visited,
.unmc .dcf-notice-message a:hover,
.unmc .dcf-notice-message a:active,
.unmc .dcf-notice-message a {
  color: $white;
}

.unmc .dcf-notice .dcf-btn-inverse-tertiary {
  @include p-4;
}


// Info
.unmc .dcf-notice-info {
  --bg-notice-info: #{$color-info};
}


// Success
.unmc .dcf-notice-success {
  --bg-notice-success: #{$color-success};
}


// Warning
.unmc .dcf-notice-warning {
  --bg-notice-warning: #{$color-warning};
}


// Danger
.unmc .dcf-notice-danger {
  --bg-notice-danger: #{$color-danger};
}
